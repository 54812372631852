.logoDisplay {
    padding-left: 20px;
    max-width: 100px;
    max-height: 80px;
}

.logoInner {
    max-width: 100px;
    max-height: 80px;
    float: left;
    padding-bottom: 10px;

}

.navbar {
    display: block;
    list-style-type: none;
    overflow: hidden;
    border-bottom: 2px solid rgb(42, 42, 42);
    background-color: #ffffff;
}

.logo {
    display: block;
    text-indent: 0px;
    width: 100px;
    height: 100px;
    background: url(../img/maxsun_logo.jpg);
    background-size: 100px 95px
}

.siteOptions {
    float: right;
    margin-top: 30px;
}

.siteOptionElement {
    margin-right: 20px;
    text-decoration: none;
    color: black;
}

.siteOptionElementLast {
    padding-left: 15px;
}