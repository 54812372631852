.whyTitle {
    text-align: center;
    padding: 10px;
    padding-bottom: 30px;
}

.whyFeatures {
    display: grid;
    column-gap: 50px;
    margin: auto;
    max-width: 1100px;
}

@media (max-width:490px) { 
    .whyFeatures {
        grid-template-columns: auto;
    }
} 

@media (min-width:490px) { 
    .whyFeatures {
        grid-template-columns: auto auto auto;
    }
} 

.whyFeature {
    max-width: 170px;
    text-align: center;
    margin: auto;
    padding: 10px;
}

.whyFeatureIcon {
    height: 70px;
    width: 70px;
}

.whyFeatureDescription {
    
}