.productpageTitle {
    text-align: center;
    padding: 20px;
}

.productpageProductGrid {
    display: grid;
    
    column-gap: 30px;
    row-gap: 20px;
    margin: auto;
    text-align: center;
    padding-bottom: 20px;
    max-width: 860px;
}

@media (max-width:860px) { 
    .productpageProductGrid {
        grid-template-columns: auto auto;
    }
} 

@media (min-width:860px) { 
    .productpageProductGrid {
        grid-template-columns: auto auto auto auto;
    }
} 


.productWindow {
    text-align: center;
    margin: auto;
    background-color: #f3f3f3;
    border-radius: 8px;
    max-width: 140px;
}

.productWindowName {
    
}

.productWindowImage {
    max-width: 100px;
    max-height: 100px;
}