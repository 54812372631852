.productBody {
    display: block;
    text-indent: 0px;
    background: url(../img/marketing/solar_panels_2_background.jpg);
    width: 100%;
    min-height: 300px;
    position: relative;
}

.productDetails {
    margin-top: 30px;
    background-color: white;
    max-width: 650px;
    position: relative;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    padding: 20px;
}

.productDetailTitle {
    padding-top: 15px;
    justify-content: center;
    align-content: center;
    text-align: center;
    font-weight: lighter;
    max-width: 65%;
    margin: auto;
    padding-bottom: 20px;
}

.productDetailDescription {
    text-align: center;
}