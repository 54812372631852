.introductionBody {
    display: block;
    text-indent: 0px;
    background: url(../img/marketing/solar_panels_background.jpg);
    width: 100%;
    min-height: 300px;
    margin-bottom: 20px;
}


.introMarketingTitle {
    padding-top: 110px;
    display: flex;
    justify-content: center;
    align-content: center;
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 2px black;
    text-align: center;
}

.introMarketingSubtitle {
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    color: white;
    font-weight: lighter;
    text-shadow: 1px 1px 2px black;
    min-width: 280px;
    max-width: 65%;
    margin: auto;
    padding-bottom: 20px;
}