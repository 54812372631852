.corporateBody {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: rgb(34, 34, 34);
    color: white;
    width: 100vw;
    overflow: hidden;
}

.corporateInner {   
    margin: 20px;
}

.corporatePhoneLink {
    color: white;
    text-decoration: none;
}